import React, {Component} from 'react';
import RavenJS from 'raven-js';

class Login extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            username: '',
            password: '',
            error: ''
        };
    }

    handleChange(event, attribute) {
        this.setState({[attribute]: event.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();

        fetch(process.env.REACT_APP_BACKEND_URL + '/users/session', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password,
                expiresIn: "36000000",
                platform: "Web",
                appName: "BackOffice",
                environmentId: "N/A"
            })
        }).then(response => {
            response.json().then(data => {
                if (response.status === 201) {
                    localStorage.authToken = data.authToken;
                    localStorage.roles = data.authorities.map(r => r.authority);
                    this.props.login();
                } else {
                    if (data) {
                        this.setState({
                            error: data.message
                        });
                    } else {
                        this.setState({
                            error: response.status + ': ' + response.statusText
                        });
                    }
                }
            });
        }).catch(errorMessage => {
            this.setState({
                error: String(errorMessage),
            });
            RavenJS.captureException(new Error(errorMessage));
        });
    }

    render() {
        return (
            <div className="container pt-3">
                <div className="row">
                    <div className="col">
                    </div>
                    <div className="col-6">
                        <h1 className='mb-5'>Please sign in</h1>

                        {this.state.error &&
                        <div className="alert alert-danger alert-dismissible">
                            {this.state.error}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        }

                        <form onSubmit={this.handleSubmit}>
                            <input type="text" className="form-control mb-3" placeholder="Username" value={this.state.username} onChange={event => this.handleChange(event, 'username')} required autoFocus/>
                            <input type="password" className="form-control mb-3" placeholder="Password" value={this.state.password} onChange={event => this.handleChange(event, 'password')} required/>
                            <input type="submit" className="btn btn-lg btn-success btn-block" value="Sign in"/>
                        </form>

                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
        );
    }

}

export default Login;